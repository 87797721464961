import React, { useCallback } from "react";
import UploadField from "../FileUpload/UploadField";
import UploadFieldMui from "../FileUpload/UploadFieldMui";
import { doFetch } from "../base/fetch";

export default function AlbumUpload({ albumId, updateAlbum }) {
  const uploadFileToServer = useCallback(
    (file) => {
      console.log("uploadFileToServer");
      let formData = new FormData();
      formData.append("file", file);
      const delay = file.size / 100;
      return doFetch({
        url: "/api/files/" + albumId,
        method: "POST",
        body: formData,
      })
        .then((obj) => updateAlbum())
        .catch((e) => console.log(e));
    },
    [albumId]
  );

  /*
    const handleChange = useCallback(e => {
        const {value, name} = e.target;
        setUpload(upls => ({...upls, [name]: value}));
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const isUpdate = id !== 'new';

        fetch('/api/uploads' + (isUpdate ? '/' + id : ''), {
            method: isUpdate ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(upload),
        });
        navigate('/uploads');
    }, [id, upload, navigate]);*/

  return (
    <>
      <UploadFieldMui uploadFileToServer={uploadFileToServer} />
      {/* <UploadField
        multiple={true}
        name="example-upload"
        maxSize={300000}
        onUpload={uploadFileToServer}
        label="Upload Files"
      /> */}
    </>
  );
}
