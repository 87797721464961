import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  defaultCookieOptions,
  isLoggedInCookie
} from "../base/cookieHandling";
import { doFetch } from "../base/fetch";

const timeToCache = 60 * 60 * 1000;
const knownAlbumsCookie = "knownAlbums";

export function checkAddAlbum(albumId, cookies, setCookie) {
  let albumsCookie = cookies[knownAlbumsCookie];
  console.log('checkAddAlbumCookie', albumsCookie, albumId)
  if (!albumsCookie) {
    albumsCookie = [];
  }
  if (!albumsCookie.includes(albumId)) {
    albumsCookie.push(albumId);
    setCookie(knownAlbumsCookie, albumsCookie, defaultCookieOptions);
  }
}

function shouldUpdateAlbum(albumId, knownAlbums) {
  const found = knownAlbums.find((a) => a.albumId == albumId);
  if (!found) return true;
  if (found.fetched) return false;
  const now = new Date().getTime();
  const timeLeft = -(now - (found.timestamp || 0) - timeToCache);
  return timeLeft < 0;
}

const emptyAlbum = (id) => ({
  albumId: id,
  name: id,
  creatorName: "",
  fetched: false,
});

function updateFetched(setKnownAlbums, albumId, alb) {
  setKnownAlbums((stateList) => {
    let saveAlb = {};
    if (alb && typeof alb === "object") {
      saveAlb = { ...alb, fetched: true, timestamp: new Date().getTime() };
    } else {
      const notFoundAlbum = stateList.find((a) => a.albumId == albumId);
      if (notFoundAlbum) {
        saveAlb = { ...notFoundAlbum, timestamp: new Date().getTime() };
      } else {
        saveAlb = {
          ...emptyAlbum(albumId),
          timestamp: new Date().getTime(),
        };
      }
    }
    stateList = stateList.filter((a) => a.albumId != saveAlb.albumId);
    stateList.push(saveAlb);
    return stateList;
  });
}
function fetchKnownAlbums(
  knownAlbumIds,
  knownAlbums,
  setKnownAlbums,
  isLoggedIn
) {
  const result = [];
  if (isLoggedIn) {
    knownAlbumIds.forEach((albumId) => {
      // Add if missing
      if (shouldUpdateAlbum(albumId, knownAlbums)) {
        const promise = doFetch({
          url: `/api/album/${albumId}`,
          ignoredStatuses: [404],
        }).then((alb) => {
          updateFetched(setKnownAlbums, albumId, alb);
        });
        result.push(promise);
      }
    });
  }
  return result;
}

function cleanupList(knownAlbumIds, setKnownAlbums) {
  // Remove when no id left, add not found, sort, cache
  setKnownAlbums((stateList) => {
    let newStateList = stateList.filter((a) =>
      knownAlbumIds.includes(a.albumId)
    );
    let notFoundAlbums = knownAlbumIds
      .filter((id) => !stateList.find((a) => a.albumId == id))
      .map((id) => emptyAlbum(id));
    newStateList.push(...notFoundAlbums);
    newStateList.sort(
      (a, b) =>
        b.fetched - a.fetched || (a.name && a.name.localeCompare(b.name))
    );
    saveKnownAlbumsCache(newStateList);
    return newStateList;
  });
}

function removeKnownAlbum(albumId, cookies, setCookie) {
  let knownAlbums = cookies[knownAlbumsCookie];
  if (knownAlbums && knownAlbums.includes(albumId)) {
    knownAlbums = knownAlbums.filter((id) => id !== albumId);
    setCookie(knownAlbumsCookie, knownAlbums, defaultCookieOptions);
  }
}
const localStorageName = "knownAlbumsCache";
function getKnownAlbumsCache() {
  const cacheString = localStorage.getItem(localStorageName);
  return cacheString ? JSON.parse(cacheString) : [];
}
function saveKnownAlbumsCache(albums) {
  localStorage.setItem(localStorageName, JSON.stringify(albums));
}
export function useKnownAlbums() {
  const [cookies, setCookie] = useCookies([
    isLoggedInCookie,
    knownAlbumsCookie,
  ]);

  const knownAlbumIds = cookies[knownAlbumsCookie] || [];
  const [knownAlbums, setKnownAlbums] = useState(getKnownAlbumsCache());
  const isLoggedIn = cookies[isLoggedInCookie];
  
  useEffect(() => {
    if (knownAlbumIds.length == 0) {
      return;
    }
  
    const doSyncKnownAlbums = async () => {
      const requests = fetchKnownAlbums(
        knownAlbumIds,
        knownAlbums,
        setKnownAlbums,
        isLoggedIn
      );
      Promise.all(requests).then(() =>
        cleanupList(knownAlbumIds, setKnownAlbums)
      );
    };
    doSyncKnownAlbums();
  }, [knownAlbumIds, isLoggedIn, setKnownAlbums]);

  function deleteKnownAlbum(albumId) {
    removeKnownAlbum(albumId, cookies, setCookie);
  }

  return [knownAlbums, deleteKnownAlbum];
}
