// Create Dialog (Mui) https://mui.com/material-ui/react-dialog/#non-modal-dialog

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
  defaultCookieOptions,
  isLoggedInCookie,
  usernameCookie,
} from "./cookieHandling";
import { doFetch } from "./fetch";

export function checkLogin() {
  const cookies = new Cookies(null, defaultCookieOptions);
  cookies.set(isLoggedInCookie, false, defaultCookieOptions);
  if (cookies.get(usernameCookie)) {
    fetch("/api/auth/checkauth", { method: "GET" })
      .then(
        (result) =>
          result.ok && cookies.set(isLoggedInCookie, true, defaultCookieOptions)
      )
      .catch((e) => {});
  }
}

export async function doSimpleLogin(username, termsApproval) {
  const loginRequest = { username, termsApproval };
  const res = await doFetch({
    url: "/api/auth/simple_signup",
    needAuth: false,
    method: "POST",
    body: loginRequest,
  }).then((r) => console.log("LoggedIn?", r));

  console.log("simpleLogin: ", res);
}
export const logoutSession = (removeCookie) => {
  removeCookie(usernameCookie, { path: "/" });
  removeCookie("JSESSIONID", { path: "/" });
};

export default function LoginDialog({ onClose, open }) {
  const [username, setUsername] = useState("");
  const [termsApproval, setTermsApproval] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate("/");
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{ p: 10 }}
      PaperProps={{
        component: "form",
        onSubmit: async (e) => {
          e.preventDefault();
          await doSimpleLogin(username, termsApproval);
        },
      }}
    >
      <DialogTitle>Please Login to view content</DialogTitle>
      <DialogContent
        dividers={false}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <DialogContentText>
          Just state your name, no account needed.
        </DialogContentText>
        <TextField
          id="username"
          value={username || ""}
          label="Your name"
          onChange={(e) => setUsername(e.target.value)}
          variant="outlined"
          autoComplete="username"
        />
        <FormControlLabel
          control={<Checkbox />}
          value={termsApproval}
          onChange={() => setTermsApproval(!termsApproval)}
          label="Accept terms"
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="outlined" disabled={!termsApproval}>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
