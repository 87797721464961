import { Paper, Link } from "@mui/material";

export default function About() {
  return (
    <>
    <p>
      This App is created by Alexis Suter find me on Github:{" "}
      <Link href="https://github.com/EaseRider" target="_blank" rel="noreferrer">https://github.com/EaseRider</Link>
      </p>
    <p>
      Connect with Google:{" "}
      <Link href="/oauth2/authorization/google" target="_self">/oauth2/authorization/google</Link>
      </p>
    </>
  );
}
