import { Cookies } from "react-cookie";
import { defaultCookieOptions, isLoggedInCookie } from "./cookieHandling";

function GlobalError(message) {
  this.message = message;
  this.stack = Error().stack;
}
GlobalError.prototype = Object.create(Error.prototype);
GlobalError.prototype.name = "GlobalError";
function UnhandledRequestError(details) {
  this.message = "Request Error";
  this.content = details;
  this.stack = Error().stack;
}
UnhandledRequestError.prototype = Object.create(Error.prototype);
UnhandledRequestError.prototype.name = "UnhandledRequestError";

class Fetch {
  constructor(addGlobalError = () => {}) {
    this.addGlobalError = addGlobalError;
  }
  cancelableFetch = async (
    url,
    options,
    unhandledStatuses = [422],
    ignoredStatuses = []
  ) => {
    const response = await this._fetch(url, options);

    if (this._unmounted) {
      throw new CanceledPromiseError();
    }
    await this._validateResponse(response, unhandledStatuses, ignoredStatuses);

    return this.parseResponseBody(response);
  };

  _validateResponse = async (response, unhandledStatuses, ignoredStatuses) => {
    if (!response.ok) {
      if (ignoredStatuses.includes(response.status)) {
        return;
      }

      const errorText = `${response.status} -  ${response.statusText}`;
      if (unhandledStatuses.includes(response.status)) {
        const errorDetails = {
          status: response.status,
          statusText: response.statusText,
          body: await this.parseResponseBody(response),
        };
        throw new UnhandledRequestError(errorDetails);
      }

      const body = await this.parseResponseBody(response);
      console.error("Error Body: ", typeof body, body);
      let error;
      if (typeof body !== "object") {
        error = errorText;
      } else if (body.error || body.detail) {
        error = { title: errorText, message: body.error || body.detail };
      } else {
        error = { title: errorText, message: body };
      }
      this.addGlobalError(error);
      throw new GlobalError(errorText);
    }
  };

  _fetch = async (url, options) => {
    try {
      return fetch(`${url}`, {
        ...options,
      });
    } catch (error) {
      this.addGlobalError(error.message);
      throw new GlobalError(error.message);
    }
  };

  doFileUpload = async (formData, url, method, updateProgress) => {
    const request = new XMLHttpRequest();

    updateProgress &&
      request.upload.addEventListener("progress", updateProgress);

    request.open(method, `${url}`);
    request.send(formData);
  };

  async parseResponseBody(response) {
    try {
      const contentType = response.headers.get("content-type");
      if (response.status === 204) return null;
      else if (contentType && contentType.indexOf("application/json") !== -1) {
        return await response.json();
      } else {
        return await response.text();
      }
    } catch (error) {
      return {};
    }
  }
}

export async function doFetch({
  url,
  needAuth = true,
  method = "GET",
  body = undefined,
  unhandledStatuses = [422],
  ignoredStatuses = [],
}) {
  const cookies = new Cookies(null, defaultCookieOptions);
  const isLoggedIn = cookies.get(isLoggedInCookie, defaultCookieOptions);
  const options = { method };
  if (!needAuth || isLoggedIn) {
    let headers = null;
    if (method != "GET" && !(body instanceof FormData)) {
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      options.body = JSON.stringify(body);
    } else if (body) {
      options.body = body;
    }
  }
  return new Fetch().cancelableFetch(
    url,
    options,
    unhandledStatuses,
    ignoredStatuses
  );
}

export async function doFetch2(
  url,
  needAuth = true,
  method = "GET",
  body = undefined
) {
  const cookies = new Cookies(null, defaultCookieOptions);
  const isLoggedIn = cookies.get(isLoggedInCookie, defaultCookieOptions);
  if (!needAuth || isLoggedIn) {
    let headers = undefined;
    if (method != "GET") {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      body = JSON.stringify(body);
    }

    return fetch(url, {
      method,
      headers,
      body,
    }).then(handleResponse(cookies));
  } else {
    return null;
  }
}

function handleResponse(cookies) {
  return (response) =>
    response.text().then((text) => {
      const data = text && response.ok && JSON.parse(text);

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return error;
        //return Promise.reject(error);
      }

      return data;
    });
}
