import { Cookies } from "react-cookie";

const darkThemeName = "dark";
const defaultThemeName = "default";
export const themeCookieName = "layoutTheme";

export const defaultCookieOptions = { sameSite: "strict", path: "/" };

export const usernameCookie = "username";
export const isLoggedInCookie = "isLoggedIn";

export function getThemeName(cookies, setCookie) {
  if (!cookies[themeCookieName]) {
    setTheme(setCookie, defaultThemeName);
  }
  return cookies[themeCookieName];
}
export function switchTheme(cookies, setCookie) {
  const themeName = getThemeName(cookies);
  switch (themeName) {
    case darkThemeName:
      setTheme(setCookie, defaultThemeName);
      break;
    case defaultThemeName:
    default:
      setTheme(setCookie, darkThemeName);
      break;
  }
}
export function setTheme(setCookie, themeName) {
  setCookie(themeCookieName, themeName, defaultCookieOptions);
}

