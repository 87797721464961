import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import AlbumView from "../Album/AlbumView";
import { CookiesProvider } from "react-cookie";
import Layout from "./Layout";
import AlbumCreate from "../Album/AlbumCreate";
import About from "../other/About";
import { checkLogin } from "./Login";
import { defaultCookieOptions } from "./cookieHandling";

const Routing = () => (
  <Routes>
    <Route path="/" exact={true} element={<AlbumCreate />} />
    <Route path="/album/:albumId" exact={true} element={<AlbumView />} />
    <Route path="/about" exact={true} element={<About />} />
  </Routes>
);

export default function App() {
  checkLogin();
  return (
    <CookiesProvider defaultSetOptions={defaultCookieOptions}>
      <BrowserRouter>
        <Layout Content={<Routing />} />
      </BrowserRouter>
    </CookiesProvider>
  );
}
