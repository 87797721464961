import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { doSimpleLogin } from "../base/Login";
import { isLoggedInCookie, usernameCookie } from "../base/cookieHandling";
import { doFetch } from "../base/fetch";

export default function AlbumCreate() {
  const [cookies, setCookie] = useCookies([usernameCookie, isLoggedInCookie]);

  const loggedInUsername = cookies[usernameCookie];
  const isLoggedIn = cookies[isLoggedInCookie];

  const [creatorName, setCreatorName] = useState(loggedInUsername);
  const [name, setName] = useState("");
  const [termsApproval, setTermsApproval] = useState(false);

  const navigate = useNavigate();

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isLoggedIn) {
        await doSimpleLogin(creatorName, termsApproval);
      }

      let album = { name, creatorName };
      console.log("Submit", album);
      doFetch({ url: "/api/album", method: "POST", body: album }).then(
        (alb) => {
          navigate(`/album/${alb.albumId}`);
        }
      );
    },
    [name, creatorName, termsApproval, isLoggedIn, navigate]
  );
  return (
    <Box
      onSubmit={submit}
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        "& .MuiFormControlLabel-root": { m: 1 },
        "& .MuiButtonBase-root": { m: 1 },
      }}
    >
      <TextField
        id="creatorName"
        value={creatorName || ""}
        label="Your name"
        onChange={(e) => setCreatorName(e.target.value)}
        variant="outlined"
        autoComplete="creatorName"
        disabled={isLoggedIn ? true : false}
      />
      <TextField
        id="name"
        value={name || ""}
        label="Album name"
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        autoComplete="albumName"
      />
      <FormControlLabel
        control={<Checkbox />}
        value={termsApproval}
        onChange={() => setTermsApproval(!termsApproval)}
        label="Accept terms"
      />
      <Button variant="outlined" disabled={!termsApproval} onClick={submit}>
        Create
      </Button>
    </Box>
  );
}
